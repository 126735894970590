import { ListViewProvider, useListView } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { WorkoutsListHeader } from "./components/header/WorkoutsListHeader";
import { WorkoutsTable } from "./table/WorkoutsTable";
import { WorkoutEditModal } from "./workout-edit-modal/WorkoutEditModal";
import { KTCard } from "../../../../../_metronic/helpers";
import { useParams } from "react-router-dom";

import { QueryResponseProvider as InstructorsQueryResponseProvider } from "../../instructor-management/instructors-list/core/QueryResponseProvider";
import { QueryResponseProvider as CustomersQueryResponseProvider } from "../../customer-management/customers-list/core/QueryResponseProvider";

const WorkoutsList = () => {
    const { itemIdForUpdate } = useListView();
    return (
        <>
            <KTCard>
                <WorkoutsListHeader />
                <WorkoutsTable />
            </KTCard>
            {itemIdForUpdate !== undefined && <WorkoutEditModal />}
        </>
    );
};

const WorkoutsListWrapper = () => {
    const { id } = useParams();
    return (
        <QueryRequestProvider>
            <QueryResponseProvider gymSlug={id}>
                <InstructorsQueryResponseProvider gymSlug={id}>
                    <CustomersQueryResponseProvider gymSlug={id}>
                        <ListViewProvider>
                            <WorkoutsList />
                        </ListViewProvider>
                    </CustomersQueryResponseProvider>
                </InstructorsQueryResponseProvider>
            </QueryResponseProvider>
        </QueryRequestProvider>
    );
};

export { WorkoutsListWrapper };
