import { KTIcon } from "../../../../../../../_metronic/helpers";
import { useListView } from "../../core/ListViewProvider";
import { WorkoutsListFilter } from "./WorkoutsListFilter";

const WorkoutsListToolbar = () => {
    const { setItemIdForUpdate } = useListView();
    const openAddAdminModal = () => {
        setItemIdForUpdate(null);
    };

    return (
        <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base"
        >
            <WorkoutsListFilter />

            {/* begin::Export */}
            <button type="button" className="btn btn-light-primary me-3">
                <KTIcon iconName="exit-up" className="fs-2" />
                Exportar
            </button>
            {/* end::Export */}

            {/* begin::Add user */}
            <button
                type="button"
                className="btn btn-primary"
                onClick={openAddAdminModal}
            >
                <KTIcon iconName="plus" className="fs-2" />
                Adicionar
            </button>
            {/* end::Add user */}
        </div>
    );
};

export { WorkoutsListToolbar };
