import axios from "axios";
import { AuthModel, UserApiResponse, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
export const GET_USER_BY_ACCESSTOKEN_URL = `/me`;
export const SUPER_ADMIN_LOGIN_URL = `/super_admin_auth/sign_in`;
export const ADMIN_LOGIN_URL = `/admin_auth/sign_in`;
export const REGISTER_URL = `/register`;
export const REQUEST_PASSWORD_URL = `/forgot_password`;

// Server should return AuthModel
export function loginSuperAdmin(email: string, password: string) {
    return axios.post<UserApiResponse>(SUPER_ADMIN_LOGIN_URL, {
        email,
        password,
    });
}
export function loginAdmin(email: string, password: string) {
    return axios.post<UserApiResponse>(ADMIN_LOGIN_URL, {
        email,
        password,
    });
}

// Server should return AuthModel
export function register(
    email: string,
    name: string,
    password: string,
    password_confirmation: string
) {
    return axios.post(REGISTER_URL, {
        email,
        name: name,
        password,
        password_confirmation,
    });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email,
    });
}

export function getUserByToken(token: string) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return axios.get<UserApiResponse>(GET_USER_BY_ACCESSTOKEN_URL, config);
}
