import axios, { AxiosResponse } from "axios";
import { User, SubscriptionPlan } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const GET_ADMINS_URL = `/admins`;
const GET_SUBSCRIPTION_PLAN_URL = `/subscription_plans`;

const getAdmins = (): Promise<User[]> => {
    return axios
        .get(`${GET_ADMINS_URL}`)
        .then((d: AxiosResponse<User[]>) => d.data);
};
const getSubscriptionPlans = (): Promise<SubscriptionPlan[]> => {
    return axios
        .get(`${GET_SUBSCRIPTION_PLAN_URL}`)
        .then((d: AxiosResponse<SubscriptionPlan[]>) => d.data);
};

export { getAdmins, getSubscriptionPlans };
