import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { WorkoutsQueryResponse, Workout } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const CUSTOMER_URL = `.lvh.me:300/api/v1/instructors/workouts`;

const getWorkouts = (
    gymSlug: string | number | undefined
): Promise<WorkoutsQueryResponse> => {
    const URL = gymSlug
        ? `http://${gymSlug}${CUSTOMER_URL}`
        : `/admins/workouts`;

    return axios
        .get(URL)
        .then(
            (response: AxiosResponse<WorkoutsQueryResponse>) => response.data
        );
};

const getWorkoutById = (
    gymSlug: string | number | undefined,
    id: ID
): Promise<Workout | undefined> => {
    const URL = gymSlug
        ? `http://${gymSlug}${CUSTOMER_URL}/${id}`
        : `/admins/workouts/${id}`;

    return axios
        .get(URL)
        .then((response: AxiosResponse<Response<Workout>>) => response.data)
        .then((response: Response<Workout>) => response.data);
};

const createWorkout = (
    gymSlug: string | number | undefined,
    workout: Workout
): Promise<Workout | undefined> => {
    const URL = gymSlug ? `http://${gymSlug}${CUSTOMER_URL}` : `/workouts`;

    const payload = {
        ...workout,
        gym_slug: gymSlug,
    };

    return axios
        .post(URL, payload)
        .then((response: AxiosResponse<Response<Workout>>) => response.data)
        .then((response: Response<Workout>) => response.data);
};

const updateWorkout = (
    gymSlug: string | number | undefined,
    workout: Workout
): Promise<Workout | undefined> => {
    const URL = gymSlug
        ? `http://${gymSlug}${CUSTOMER_URL}/${workout.id}`
        : `/admins/workouts/${workout.id}`;

    return axios
        .put(URL, workout)
        .then((response: AxiosResponse<Response<Workout>>) => response.data)
        .then((response: Response<Workout>) => response.data);
};

const deleteWorkout = (
    gymSlug: string | number | undefined,
    workoutId: ID
): Promise<void> => {
    const URL = gymSlug
        ? `http://${gymSlug}${CUSTOMER_URL}/${workoutId}`
        : `/admins/workouts/${workoutId}`;

    return axios.delete(URL).then(() => {});
};

const deleteSelectedWorkouts = (
    gymSlug: string | number | undefined,
    adminIds: Array<ID>
): Promise<void> => {
    const URL = gymSlug ? `http://${gymSlug}${CUSTOMER_URL}` : `/workouts`;

    const requests = adminIds.map((id) => axios.delete(`${URL}/${id}`));
    return axios.all(requests).then(() => {});
};

export {
    getWorkouts,
    getWorkoutById,
    createWorkout,
    updateWorkout,
    deleteWorkout,
    deleteSelectedWorkouts,
};
