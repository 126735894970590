import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { CustomersQueryResponse, Customer } from "./_models";

const CUSTOMER_URL = `/admins/customers`;

const getCustomers = (
    gymSlug: string | number | undefined
): Promise<CustomersQueryResponse> => {
    const URL = `${CUSTOMER_URL}?tenant=${gymSlug}`;

    return axios
        .get(URL)
        .then(
            (response: AxiosResponse<CustomersQueryResponse>) => response.data
        );
};

const getCustomerById = (
    gymSlug: string | number | undefined,
    id: ID
): Promise<Customer | undefined> => {
    const URL = `${CUSTOMER_URL}/${id}?tenant=${gymSlug}`;

    return axios
        .get(URL)
        .then((response: AxiosResponse<Response<Customer>>) => response.data)
        .then((response: Response<Customer>) => response.data);
};

const getCustomerByCpf = (
    gymSlug: string | number | undefined,
    cpf: string
): Promise<Customer | undefined> => {
    const URL = `${CUSTOMER_URL}/find_by_cpf?cpf=${cpf}&tenant=${gymSlug}`;

    return axios
        .get(URL)
        .then((response: AxiosResponse<Response<Customer>>) => response.data)
        .then((response: Response<Customer>) => response.data);
};

const createCustomer = (
    gymSlug: string | number | undefined,
    customer: Customer
): Promise<Customer | undefined> => {
    const URL = `${CUSTOMER_URL}?tenant=${gymSlug}`;

    const payload = {
        ...customer,
        gym_slug: gymSlug,
    };

    return axios
        .post(URL, payload)
        .then((response: AxiosResponse<Response<Customer>>) => response.data)
        .then((response: Response<Customer>) => response.data);
};

const updateCustomer = (
    gymSlug: string | number | undefined,
    customer: Customer
): Promise<Customer | undefined> => {
    const URL = `${CUSTOMER_URL}/${customer.id}?tenant=${gymSlug}`;

    return axios
        .put(URL, customer)
        .then((response: AxiosResponse<Response<Customer>>) => response.data)
        .then((response: Response<Customer>) => response.data);
};

const deleteCustomer = (
    gymSlug: string | number | undefined,
    customerId: ID
): Promise<void> => {
    const URL = `${CUSTOMER_URL}/${customerId}?tenant=${gymSlug}`;

    return axios.delete(URL).then(() => {});
};

const deleteSelectedCustomers = (
    gymSlug: string | number | undefined,
    adminIds: Array<ID>
): Promise<void> => {
    const URL = `${CUSTOMER_URL}?tenant=${gymSlug}`;

    const requests = adminIds.map((id) => axios.delete(`${URL}/${id}`));
    return axios.all(requests).then(() => {});
};

export {
    getCustomers,
    getCustomerById,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    deleteSelectedCustomers,
    getCustomerByCpf,
};
