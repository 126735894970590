/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import {
    createResponseContext,
    initialQueryResponse,
    initialQueryState,
    PaginationState,
    QUERIES,
    stringifyRequestQuery,
    WithChildren,
} from "../../../../../../_metronic/helpers";
import { getCustomers } from "./_requests";
import { Customer } from "./_models";
import { useQueryRequest } from "./QueryRequestProvider";

const QueryResponseContext =
    createResponseContext<Customer>(initialQueryResponse);
const QueryResponseProvider: FC<
    WithChildren & { gymSlug?: number | string }
> = ({ children, gymSlug }) => {
    const { state } = useQueryRequest();
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

    useEffect(() => {
        if (query !== updatedQuery) {
            setQuery(updatedQuery);
        }
    }, [updatedQuery]);

    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `${QUERIES.CUSTOMERS_LIST}-${query}`,
        () => {
            return getCustomers(gymSlug);
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    );

    return (
        <QueryResponseContext.Provider
            value={{ isLoading: isFetching, refetch, query, response }}
        >
            {children}
        </QueryResponseContext.Provider>
    );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
    const { response } = useQueryResponse();
    if (!response) {
        return [];
    }

    return response?.data || [];
};

const useQueryResponsePagination = () => {
    const defaultPaginationState: PaginationState = {
        links: [],
        ...initialQueryState,
    };

    const { response } = useQueryResponse();
    if (!response || !response.payload || !response.payload.pagination) {
        return defaultPaginationState;
    }

    return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
    const { isLoading } = useQueryResponse();
    return isLoading;
};

export {
    QueryResponseProvider,
    useQueryResponse,
    useQueryResponseData,
    useQueryResponsePagination,
    useQueryResponseLoading,
};
