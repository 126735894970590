import { useMemo } from "react";
import { useTable, ColumnInstance, Row } from "react-table";
import { CustomHeaderColumn } from "./columns/WorkoutHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import {
    useQueryResponseData,
    useQueryResponseLoading,
} from "../core/QueryResponseProvider";
import { adminsColumns } from "./columns/_columns";
import { Workout } from "../core/_models";
import { WorkoutsListLoading } from "../components/loading/WorkoutsListLoading";
import { WorkoutsListPagination } from "../components/pagination/WorkoutsListPagination";
import { KTCardBody } from "../../../../../../_metronic/helpers";

const WorkoutsTable = () => {
    const admins = useQueryResponseData();
    const isLoading = useQueryResponseLoading();
    const data = useMemo(() => admins, [admins]);
    const columns = useMemo(() => adminsColumns, []);
    const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
        useTable({
            columns,
            data,
        });

    return (
        <KTCardBody className="py-4">
            <div className="table-responsive">
                <table
                    id="kt_table_users"
                    className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                    {...getTableProps()}
                >
                    <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            {headers.map((column: ColumnInstance<Workout>) => (
                                <CustomHeaderColumn
                                    key={column.id}
                                    column={column}
                                />
                            ))}
                        </tr>
                    </thead>
                    <tbody
                        className="text-gray-600 fw-bold"
                        {...getTableBodyProps()}
                    >
                        {rows.length > 0 ? (
                            rows.map((row: Row<Workout>, i) => {
                                prepareRow(row);
                                return (
                                    <CustomRow
                                        row={row}
                                        key={`row-${i}-${row.id}`}
                                    />
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                                        Não foram encontrados registros
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <WorkoutsListPagination />
            {isLoading && <WorkoutsListLoading />}
        </KTCardBody>
    );
};

export { WorkoutsTable };
