const QUERIES = {
    ADMINS_LIST: "admins-list",
    CHECKINS_LIST: "checkins-list",
    CHARGES_LIST: "charges-list",
    CUSTOMER_SHOW: "customer-show",
    CUSTOMERS_LIST: "customers-list",
    ENROLLMENTS_LIST: "enrollments-list",
    EXAMINATIONS_LIST: "examination-list",
    GYM_SHOW: "gym-show",
    GYMS_LIST: "gyms-list",
    INSTRUCTORS_LIST: "instructors-list",
    PLANS_LIST: "plans-list",
    PAYMENTS_LIST: "payments-list",
    RECEPCIONIST_SHOW: "recepcionist-show",
    RECEPCIONISTS_LIST: "recepcionists-list",
    SUBSCRIPTION_PLANS_LIST: "subscription-plans-list",
    USERS_LIST: "users-list",
    WORKOUTS_LIST: "workouts-list",
};

export { QUERIES };
