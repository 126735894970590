// @ts-nocheck
import { Column } from "react-table";
import { WorkoutInfoCell } from "./WorkoutInfoCell";
import { WorkoutLastLoginCell } from "./WorkoutLastLoginCell";
import { WorkoutTwoStepsCell } from "./WorkoutTwoStepsCell";
import { WorkoutActionsCell } from "./WorkoutActionsCell";
import { WorkoutSelectionCell } from "./WorkoutSelectionCell";
import { WorkoutCustomHeader } from "./WorkoutCustomHeader";
import { WorkoutSelectionHeader } from "./WorkoutSelectionHeader";
import { Workout } from "../../core/_models";

const formatDate = (dateString: string | undefined) => {
    if (dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        return `${day}-${month}-${year} - ${hours}:${minutes}`;
    }
    return "";
};

const adminsColumns: ReadonlyArray<Column<Workout>> = [
    {
        Header: (props) => <WorkoutSelectionHeader tableProps={props} />,
        id: "selection",
        Cell: ({ ...props }) => (
            <WorkoutSelectionCell id={props.data[props.row.index].id} />
        ),
    },
    {
        Header: (props) => (
            <WorkoutCustomHeader
                tableProps={props}
                title="Treino"
                className="min-w-125px"
            />
        ),
        accessor: "name",
    },
    {
        Header: (props) => (
            <WorkoutCustomHeader
                tableProps={props}
                title="Nome do cliente"
                className="min-w-125px"
            />
        ),
        accessor: "customer.name",
    },

    {
        Header: (props) => (
            <WorkoutCustomHeader
                tableProps={props}
                title="Criação"
                className="min-w-125px"
            />
        ),
        accessor: "created_at",
        Cell: ({ value }) => formatDate(value),
    },
    {
        Header: (props) => (
            <WorkoutCustomHeader
                tableProps={props}
                title="Ações"
                className="text-end min-w-100px"
            />
        ),
        id: "actions",
        Cell: ({ ...props }) => (
            <WorkoutActionsCell id={props.data[props.row.index].id} />
        ),
    },
];

export { adminsColumns };
