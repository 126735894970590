/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { KTIcon } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useAuth } from "../../../../app/modules/auth";

export function AsideMenuMain() {
    const intl = useIntl();
    const { currentUser } = useAuth();
    const gyms = currentUser?.gyms;

    const renderMenuItems = (gym) => (
        <>
            <AsideMenuItem
                to={`/apps/gym-management/gyms/${gym.slug}`}
                icon="black-right"
                title="Dashboard"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                to={`/apps/gym-management/gyms/${gym.slug}/recepcionists`}
                icon="black-right"
                title="Recepcionistas"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                to={`/apps/gym-management/gyms/${gym.slug}/plans`}
                icon="black-right"
                title="Planos"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                to={`/apps/gym-management/gyms/${gym.slug}/customers`}
                icon="black-right"
                title="Clientes"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                to={`/apps/gym-management/gyms/${gym.slug}/enrollments`}
                icon="black-right"
                title="Matrículas"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                to={`/apps/gym-management/gyms/${gym.slug}/instructors`}
                icon="black-right"
                title="Instrutores"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                to={`/apps/gym-management/gyms/${gym.slug}/examinations`}
                icon="black-right"
                title="Avaliações"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                to={`/apps/gym-management/gyms/${gym.slug}/workouts`}
                icon="black-right"
                title="Treinos"
                fontIcon="bi-layers"
            />
        </>
    );

    return (
        <>
            {currentUser?.user_type === "SuperAdmin" && (
                <>
                    <div className="menu-item">
                        <div className="menu-content pt-8 pb-2">
                            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                Super Admin
                            </span>
                        </div>
                    </div>
                    <AsideMenuItem
                        to="/apps/admin-management/admins"
                        icon="black-right"
                        title="Administradores"
                        fontIcon="bi-app-indicator"
                    />
                    <AsideMenuItem
                        to="/apps/subscription-plan-management/subscription-plans"
                        icon="black-right"
                        title="Planos"
                        fontIcon="bi-app-indicator"
                    />
                    <AsideMenuItem
                        to="/apps/gym-management/gyms"
                        icon="black-right"
                        title="Academias"
                        fontIcon="bi-layers"
                    />
                </>
            )}

            {currentUser?.user_type === "Admin" && (
                <>
                    <div className="menu-item">
                        <div className="menu-content pt-8 pb-2">
                            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                Gerenciamento de Academias
                            </span>
                        </div>
                    </div>

                    {gyms?.map((gym, index) => (
                        <div key={index}>
                            {gyms.length === 1 ? (
                                renderMenuItems(gym)
                            ) : (
                                <AsideMenuItemWithSub
                                    to="/crafted/pages"
                                    title={gym.name}
                                    fontIcon="bi-archive"
                                    icon="black-right"
                                >
                                    {renderMenuItems(gym)}
                                </AsideMenuItemWithSub>
                            )}
                        </div>
                    ))}
                </>
            )}
        </>
    );
}
