import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { InstructorsQueryResponse, Instructor } from "./_models";

const INSTRUCTORS_URL = `/admins/instructors`;

const getInstructors = (
    gymSlug: string | number | undefined
): Promise<InstructorsQueryResponse> => {
    const URL = `${INSTRUCTORS_URL}?tenant=${gymSlug}`;

    return axios
        .get(URL)
        .then(
            (response: AxiosResponse<InstructorsQueryResponse>) => response.data
        );
};

const getInstructorById = (
    gymSlug: string | number | undefined,
    id: ID
): Promise<Instructor | undefined> => {
    const URL = `${INSTRUCTORS_URL}/${id}?tenant=${gymSlug}`;

    return axios
        .get(URL)
        .then((response: AxiosResponse<Response<Instructor>>) => response.data)
        .then((response: Response<Instructor>) => response.data);
};

const createInstructor = (
    gymSlug: string | number | undefined,
    instructor: Instructor
): Promise<Instructor | undefined> => {
    const URL = `${INSTRUCTORS_URL}?tenant=${gymSlug}`;

    const payload = {
        ...instructor,
        gym_slug: gymSlug,
    };

    return axios
        .post(URL, payload)
        .then((response: AxiosResponse<Response<Instructor>>) => response.data)
        .then((response: Response<Instructor>) => response.data);
};

const updateInstructor = (
    gymSlug: string | number | undefined,
    instructor: Instructor
): Promise<Instructor | undefined> => {
    const URL = `${INSTRUCTORS_URL}/${instructor.id}?tenant=${gymSlug}`;

    return axios
        .put(URL, instructor)
        .then((response: AxiosResponse<Response<Instructor>>) => response.data)
        .then((response: Response<Instructor>) => response.data);
};

const deleteInstructor = (
    gymSlug: string | number | undefined,
    instructorId: ID
): Promise<void> => {
    const URL = `${INSTRUCTORS_URL}/${instructorId}?tenant=${gymSlug}`;

    return axios.delete(URL).then(() => {});
};

const deleteSelectedInstructors = (
    gymSlug: string | number | undefined,
    adminIds: Array<ID>
): Promise<void> => {
    const URL = `${INSTRUCTORS_URL}?tenant=${gymSlug}`;

    const requests = adminIds.map((id) => axios.delete(`${URL}/${id}`));
    return axios.all(requests).then(() => {});
};

export {
    getInstructors,
    getInstructorById,
    createInstructor,
    updateInstructor,
    deleteInstructor,
    deleteSelectedInstructors,
};
