import { useEffect, useState } from "react";
import { EnableSidebar, PageTitle } from "../../../_metronic/layout/core";
import { useAuth } from "../../modules/auth";
import { getAdmins, getSubscriptionPlans } from "./_requests";
import { User, SubscriptionPlan } from "./_models";

import { AdminsTable } from "./components/AdminsTable";
import { SubscriptionPlansList } from "./components/SubscriptionPlansList";

const DashboardPage = () => {
    const [admins, setAdmins] = useState<User[]>([]);
    const [subscriptionPlans, setSubscriptionPlans] = useState<
        SubscriptionPlan[]
    >([]);

    const fetchAdmins = async () => {
        try {
            const response: User[] = await getAdmins();
            setAdmins(response);
        } catch (error) {
            console.error("Error fetching admins:", error);
        }
    };

    const fetchPlans = async () => {
        try {
            const response: SubscriptionPlan[] = await getSubscriptionPlans();
            setSubscriptionPlans(response);
        } catch (error) {
            console.error("Error fetching plans:", error);
        }
    };

    useEffect(() => {
        fetchAdmins();
        fetchPlans();
    }, []);

    return (
        <>
            <AdminsTable admins={admins} className="mb-5 mb-xl-10" />
            <SubscriptionPlansList
                subscriptionPlans={subscriptionPlans}
                className="mb-5 mb-xl-10"
            />
        </>
    );
};

const DashboardWrapper = () => {
    const { currentUser } = useAuth();

    return (
        <EnableSidebar>
            <PageTitle description="Você tem novos cadastros" breadcrumbs={[]}>
                {`Olá, ${currentUser?.name}`}
            </PageTitle>
            <DashboardPage />
        </EnableSidebar>
    );
};

export { DashboardWrapper };
