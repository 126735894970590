import { Route, Routes } from "react-router-dom";
import { Registration } from "./components/SuperAdmin/Registration";
import { ForgotPassword } from "./components/SuperAdmin/ForgotPassword";
import { Login } from "./components/SuperAdmin/Login";
import { SuperAdminAuthLayout } from "./SuperAdminAuthLayout";

const SuperAdminAuthPage = () => (
    <Routes>
        <Route element={<SuperAdminAuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="registration2" element={<Registration />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route index element={<Login />} />
        </Route>
    </Routes>
);

export { SuperAdminAuthPage };
