import { FC, useState } from "react";
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider, FormikErrors } from "formik";
import { KTIcon, isNotEmpty } from "../../../../../../_metronic/helpers";
import { Workout, Exercise, initialWorkout } from "../core/_models";
import clsx from "clsx";
import { useListView } from "../core/ListViewProvider";
import { WorkoutsListLoading } from "../components/loading/WorkoutsListLoading";
import { createWorkout, updateWorkout } from "../core/_requests";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { useParams } from "react-router-dom";
import { useQueryResponseData as useInstructorQueryResponseData } from "../../../instructor-management/instructors-list/core/QueryResponseProvider";
import { useQueryResponseData as useCustomerQueryResponseData } from "../../../customer-management/customers-list/core/QueryResponseProvider";

type Props = {
    isWorkoutLoading: boolean;
    workout: Workout;
};

const formatDate = (dateString: string | undefined) => {
    if (dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
    return "";
};

const editWorkoutSchema = Yup.object().shape({
    instructor_id: Yup.number().required("Instructor ID is required"),
    customer_id: Yup.number().required("Customer ID is required"),
    name: Yup.string().required("Name is required"),
    exercises_attributes: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Exercise name is required"),
            description: Yup.string(),
        })
    ),
});

const isFormikErrors = (errors: any): errors is FormikErrors<Exercise> => {
    return (
        typeof errors === "object" && errors !== null && !Array.isArray(errors)
    );
};

const WorkoutEditModalForm: FC<Props> = ({ workout, isWorkoutLoading }) => {
    const [error, setError] = useState<string | null>(null);
    const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
    const { setItemIdForUpdate } = useListView();
    const { refetch } = useQueryResponse();
    const { id: gymSlug } = useParams();

    const instructors = useInstructorQueryResponseData();
    const customers = useCustomerQueryResponseData();

    const [workoutForEdit] = useState<Workout>({
        ...workout,
        expiration:
            formatDate(workout.expiration) ||
            formatDate(new Date().toISOString()),
        exercises: workout.exercises || initialWorkout.exercises,
    });

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch();
        }
        setItemIdForUpdate(undefined);
    };

    const handleAlertClose = () => {
        setIsAlertVisible(false);
    };

    const formik = useFormik({
        initialValues: workoutForEdit,
        validationSchema: editWorkoutSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                const { exercises, ...otherValues } = values;
                const payload = {
                    ...otherValues,
                    gym_slug: gymSlug,
                };

                if (isNotEmpty(values.id)) {
                    await updateWorkout(gymSlug as any, payload);
                } else {
                    await createWorkout(gymSlug as any, payload);
                }

                setError(null);
                setIsAlertVisible(false);
            } catch (ex: any) {
                console.error(ex);

                const messages =
                    ex.response.data.errors.full_messages.join("\n");
                if (messages) {
                    setError(messages);
                } else {
                    setError(
                        "Ocorreu um erro ao processar a solicitação. Por favor, tente novamente."
                    );
                }
                setIsAlertVisible(true);
            } finally {
                setSubmitting(false);
                cancel(true);
            }
        },
    });

    return (
        <>
            {isAlertVisible && error && (
                <div className="alert bg-light-danger d-flex align-items-center p-8 mb-10">
                    <div className="d-flex flex-column text-danger">
                        <h5 className="mb-1">Erro</h5>
                        <span>{error}</span>
                    </div>
                    <button
                        type="button"
                        className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                        onClick={handleAlertClose}
                    >
                        <div className="btn btn-sm btn-icon-danger btn-active-danger">
                            <KTIcon iconName="cross" className="fs-2" />
                        </div>
                    </button>
                </div>
            )}

            <form
                id="kt_modal_add_user_form"
                className="form"
                onSubmit={formik.handleSubmit}
                noValidate
            >
                <div
                    className="d-flex flex-column scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                    data-kt-scroll-offset="300px"
                >
                    <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                            Instructor ID
                        </label>
                        <select
                            {...formik.getFieldProps("instructor_id")}
                            name="instructor_id"
                            className={clsx(
                                "form-select form-select-solid mb-3 mb-lg-0",
                                {
                                    "is-invalid":
                                        formik.touched.instructor_id &&
                                        formik.errors.instructor_id,
                                },
                                {
                                    "is-valid":
                                        formik.touched.instructor_id &&
                                        !formik.errors.instructor_id,
                                }
                            )}
                            disabled={formik.isSubmitting || isWorkoutLoading}
                        >
                            <option value="">Select Instructor</option>
                            {instructors?.map((instructor: any) => (
                                <option
                                    key={instructor.id}
                                    value={instructor.id}
                                >
                                    {instructor.name}
                                </option>
                            ))}
                        </select>
                        {formik.touched.instructor_id &&
                            formik.errors.instructor_id && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">
                                            {formik.errors.instructor_id}
                                        </span>
                                    </div>
                                </div>
                            )}
                    </div>

                    <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                            Customer ID
                        </label>
                        <select
                            {...formik.getFieldProps("customer_id")}
                            name="customer_id"
                            className={clsx(
                                "form-select form-select-solid mb-3 mb-lg-0",
                                {
                                    "is-invalid":
                                        formik.touched.customer_id &&
                                        formik.errors.customer_id,
                                },
                                {
                                    "is-valid":
                                        formik.touched.customer_id &&
                                        !formik.errors.customer_id,
                                }
                            )}
                            disabled={formik.isSubmitting || isWorkoutLoading}
                        >
                            <option value="">Select Customer</option>
                            {customers?.map((customer: any) => (
                                <option key={customer.id} value={customer.id}>
                                    {customer.name}
                                </option>
                            ))}
                        </select>
                        {formik.touched.customer_id &&
                            formik.errors.customer_id && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">
                                            {formik.errors.customer_id}
                                        </span>
                                    </div>
                                </div>
                            )}
                    </div>

                    <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                            Name
                        </label>
                        <input
                            placeholder="Name"
                            {...formik.getFieldProps("name")}
                            type="text"
                            name="name"
                            className={clsx(
                                "form-control form-control-solid mb-3 mb-lg-0",
                                {
                                    "is-invalid":
                                        formik.touched.name &&
                                        formik.errors.name,
                                },
                                {
                                    "is-valid":
                                        formik.touched.name &&
                                        !formik.errors.name,
                                }
                            )}
                            autoComplete="off"
                            disabled={formik.isSubmitting || isWorkoutLoading}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">
                                        {formik.errors.name}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>

                    <FormikProvider value={formik}>
                        <FieldArray
                            name="exercises_attributes"
                            render={({ push, remove }) => (
                                <>
                                    <div className="mb-7">
                                        <h5 className="mb-2">Exercises</h5>
                                        {formik.values.exercises_attributes?.map(
                                            (exercise, index) => (
                                                <div
                                                    key={index}
                                                    className="d-flex mb-3"
                                                >
                                                    <div className="me-3">
                                                        <input
                                                            placeholder="Exercise Name"
                                                            {...formik.getFieldProps(
                                                                `exercises_attributes.${index}.name`
                                                            )}
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            disabled={
                                                                formik.isSubmitting ||
                                                                isWorkoutLoading
                                                            }
                                                        />
                                                        {formik.touched
                                                            .exercises_attributes?.[
                                                            index
                                                        ] &&
                                                            formik.errors
                                                                .exercises_attributes?.[
                                                                index
                                                            ] &&
                                                            isFormikErrors(
                                                                formik.errors
                                                                    .exercises_attributes[
                                                                    index
                                                                ]
                                                            ) &&
                                                            (
                                                                formik.errors
                                                                    .exercises_attributes[
                                                                    index
                                                                ] as FormikErrors<Exercise>
                                                            ).name && (
                                                                <div className="fv-plugins-message-container">
                                                                    <div className="fv-help-block">
                                                                        <span role="alert">
                                                                            {
                                                                                (
                                                                                    formik
                                                                                        .errors
                                                                                        .exercises_attributes[
                                                                                        index
                                                                                    ] as FormikErrors<Exercise>
                                                                                )
                                                                                    .name
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>

                                                    <div className="me-3">
                                                        <input
                                                            placeholder="Description"
                                                            {...formik.getFieldProps(
                                                                `exercises_attributes.${index}.description`
                                                            )}
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            disabled={
                                                                formik.isSubmitting ||
                                                                isWorkoutLoading
                                                            }
                                                        />
                                                    </div>

                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-light-danger btn-sm"
                                                            onClick={() =>
                                                                remove(index)
                                                            }
                                                            disabled={
                                                                formik.isSubmitting ||
                                                                isWorkoutLoading
                                                            }
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-light-primary btn-sm"
                                            onClick={() =>
                                                push({
                                                    name: "",
                                                    description: "",
                                                })
                                            }
                                            disabled={
                                                formik.isSubmitting ||
                                                isWorkoutLoading
                                            }
                                        >
                                            Add Exercise
                                        </button>
                                    </div>
                                </>
                            )}
                        />
                    </FormikProvider>
                </div>

                <div className="text-center pt-15">
                    <button
                        type="reset"
                        onClick={() => cancel()}
                        className="btn btn-light me-3"
                        data-kt-users-modal-action="cancel"
                        disabled={formik.isSubmitting || isWorkoutLoading}
                    >
                        Cancelar
                    </button>

                    <button
                        type="submit"
                        className="btn btn-primary"
                        data-kt-users-modal-action="submit"
                        disabled={
                            isWorkoutLoading ||
                            formik.isSubmitting ||
                            !formik.isValid ||
                            !formik.touched
                        }
                    >
                        <span className="indicator-label">Enviar</span>
                        {(formik.isSubmitting || isWorkoutLoading) && (
                            <span className="indicator-progress">
                                Por favor aguarde...{" "}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        )}
                    </button>
                </div>
            </form>
            {(formik.isSubmitting || isWorkoutLoading) && (
                <WorkoutsListLoading />
            )}
        </>
    );
};

export { WorkoutEditModalForm };
