import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { WorkoutsListWrapper } from "./workouts-list/WorkoutsList";

const workoutsBreadcrumbs: Array<PageLink> = [
    {
        title: "Gerenciamento de Treinos",
        path: "/apps/workout-management/workouts",
        isSeparator: false,
        isActive: false,
    },
    {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
    },
];

const WorkoutsPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path="workouts"
                    element={
                        <>
                            <PageTitle breadcrumbs={workoutsBreadcrumbs}>
                                Treinos
                            </PageTitle>
                            <WorkoutsListWrapper />
                        </>
                    }
                />
            </Route>
            <Route
                index
                element={<Navigate to="/apps/workout-management/workouts" />}
            />
        </Routes>
    );
};

export default WorkoutsPage;
