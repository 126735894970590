import axios, { AxiosInstance } from "axios";
import { AuthModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "gh_local_key";

const getAuth = (): AuthModel | undefined => {
    if (!localStorage) {
        return;
    }

    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (!lsValue) {
        return;
    }

    try {
        const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
        if (auth) {
            // You can easily check auth_token expiration also
            return auth;
        }
    } catch (error) {
        console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
    }
};

const setAuth = (auth: AuthModel) => {
    if (!localStorage) {
        return;
    }

    try {
        const lsValue = JSON.stringify(auth);
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
    } catch (error) {
        console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
    }
};

const removeAuth = () => {
    if (!localStorage) {
        return;
    }

    try {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    } catch (error) {
        console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
    }
};

export function setupAxios(axios: AxiosInstance) {
    // Defina a baseURL com base no ambiente
    axios.defaults.baseURL =
        process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_LOCAL
            : process.env.REACT_APP_API_URL_STAGING;

    axios.defaults.headers.Accept = "application/json";
    axios.interceptors.request.use(
        (config) => {
            const auth = getAuth();
            if (auth && auth.authorization) {
                config.headers.Authorization = `Bearer ${auth.authorization}`;
            }

            return config;
        },
        (err) => Promise.reject(err)
    );
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
