import React, { useEffect } from "react";
import { useLocation } from "react-router";
import clsx from "clsx";
import { useLayout } from "../core";
import { DrawerComponent } from "../../assets/ts/components";
import { KTIcon, WithChildren } from "../../helpers";

const Content: React.FC<WithChildren> = ({ children }) => {
    const { classes } = useLayout();
    const location = useLocation();
    useEffect(() => {
        DrawerComponent.hideAll();
    }, [location]);

    return (
        <div
            id="kt_content_container"
            className={clsx(classes.contentContainer.join(" "))}
        >
            {/* <div className="alert bg-light-danger d-flex align-items-center p-8 mb-10">
                <div className="d-flex flex-column text-danger">
                    <h5 className="mb-1">Erro</h5>
                    <span>Parece que há algum erro na sua requisição</span>
                </div>
                <button
                    type="button"
                    className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                    data-bs-dismiss="alert"
                >
                    <div
                        className="btn btn-sm btn-icon-danger btn-active-danger"
                        id="kt_drawer_chat_close"
                    >
                        <KTIcon iconName="cross" className="fs-2" />
                    </div>
                </button>
            </div> */}

            {children}
        </div>
    );
};

export { Content };
