import { useQueryClient, useMutation } from "react-query";
import { QUERIES } from "../../../../../../../_metronic/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteSelectedWorkouts } from "../../core/_requests";
import { useParams } from "react-router-dom";

const WorkoutsListGrouping = () => {
    const { selected, clearSelected } = useListView();
    const queryClient = useQueryClient();
    const { query } = useQueryResponse();
    const { id: gymSlug } = useParams();

    const deleteSelectedItems = useMutation(
        () => deleteSelectedWorkouts(gymSlug, selected),
        {
            // 💡 response of the mutation is passed to onSuccess
            onSuccess: () => {
                // ✅ update detail view directly
                queryClient.invalidateQueries([
                    `${QUERIES.WORKOUTS_LIST}-${query}`,
                ]);
                clearSelected();
            },
        }
    );

    return (
        <div className="d-flex justify-content-end align-items-center">
            <div className="fw-bolder me-5">
                <span className="me-2">{selected.length}</span> Selected
            </div>

            <button
                type="button"
                className="btn btn-danger"
                onClick={async () => await deleteSelectedItems.mutateAsync()}
            >
                Deletar Selecionados
            </button>
        </div>
    );
};

export { WorkoutsListGrouping };
