import { ID, Response } from "../../../../../../_metronic/helpers";

export type Exercise = {
    id?: ID;
    name: string;
    description?: string;
};

export type Workout = {
    id?: ID;
    name: string;
    expiration?: string;
    customer_id?: ID;
    instructor_id?: ID;
    created_at?: string;
    updated_at?: string;
    gym_slug?: string;
    exercises_attributes?: Array<Exercise>;
    exercises?: Array<Exercise>;
};

export type WorkoutsQueryResponse = Response<Array<Workout>>;

export const initialWorkout: Workout = {
    name: "",
    expiration: "",
    customer_id: undefined,
    instructor_id: undefined,
    gym_slug: undefined,
    exercises_attributes: [{ name: "", description: "" }],
    exercises: [],
};
