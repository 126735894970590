/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import React from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Dropdown1 } from "../../../../_metronic/partials";
import { SubscriptionPlan } from "../_models";

type Props = {
    className: string;
    subscriptionPlans: SubscriptionPlan[] | undefined;
};

const SubscriptionPlansList: React.FC<Props> = ({
    className,
    subscriptionPlans,
}) => {
    return (
        <div className={clsx("card", className)}>
            {/* begin::Header */}
            <div className="card-header align-items-center border-0 mt-3">
                <h3 className="card-title align-items-start flex-column">
                    <span className="fw-bolder text-dark fs-3">
                        Planos de Subscrição
                    </span>
                    <span className="text-gray-400 mt-2 fw-bold fs-6">
                        Atualização dos planos
                    </span>
                </h3>
                <div className="card-toolbar">
                    {/* begin::Menu */}
                    <button
                        type="button"
                        className="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-flip="top-end"
                    >
                        <KTIcon iconName="category" className="fs-2" />
                    </button>
                    <Dropdown1 />
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            {!subscriptionPlans || subscriptionPlans.length === 0 ? (
                <p>No plans</p>
            ) : (
                <div className="card-body pt-5">
                    {subscriptionPlans.map((subscriptionPlan) => (
                        <div className="d-flex mb-7" key={subscriptionPlan.id}>
                            <div className="symbol symbol-60px symbol-2by3 flex-shrink-0 me-4">
                                <img
                                    src={toAbsoluteUrl(
                                        "/media/stock/600x400/img-3.jpg"
                                    )}
                                    className="mw-100"
                                    alt=""
                                />
                            </div>
                            <div className="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                                    <a
                                        href="#"
                                        className="fs-5 text-gray-800 text-hover-primary fw-bolder"
                                    >
                                        {subscriptionPlan.name}
                                    </a>
                                    <span className="text-gray-400 fw-bold fs-7 my-1">
                                        R$ {subscriptionPlan.value}
                                    </span>
                                    <span className="text-gray-400 fw-bold fs-7">
                                        {subscriptionPlan.name}
                                    </span>
                                </div>
                                <div className="text-end py-lg-0 py-2">
                                    <span className="text-gray-800 fw-boldest fs-3">
                                        R$ 24,900
                                    </span>
                                    <span className="text-gray-400 fs-7 fw-bold d-block">
                                        Vendas
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {/* end::Body */}
        </div>
    );
};

export { SubscriptionPlansList };
