import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { DisableSidebar } from "../../_metronic/layout/core";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import RecepcionistsPage from "../modules/apps/recepcionist-management/RecepcionistsPage";
import WorkoutsPage from "../modules/apps/workout-management/WorkoutsPage";

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
    const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
    const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
    const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
    const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
    const AdminsPage = lazy(
        () => import("../modules/apps/admin-management/AdminsPage")
    );
    const SubscriptionPlansPage = lazy(
        () =>
            import(
                "../modules/apps/subscription-plan-management/SubscriptionPlansPage"
            )
    );
    const PlansPage = lazy(
        () => import("../modules/apps/plan-management/PlansPage")
    );
    const GymsPage = lazy(
        () => import("../modules/apps/gym-management/GymsPage")
    );
    const CustomersPage = lazy(
        () => import("../modules/apps/customer-management/CustomersPage")
    );
    const EnrollmentsPage = lazy(
        () => import("../modules/apps/enrollment-management/EnrollmentsPage")
    );
    const InstructorsPage = lazy(
        () => import("../modules/apps/instructor-management/InstructorsPage")
    );

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registration */}
                <Route
                    path="admin/auth/*"
                    element={<Navigate to="/apps/gym-management/gyms" />}
                />
                <Route
                    path="super_admin/auth/*"
                    element={<Navigate to="/apps/admin-management/admins" />}
                />
                {/* Pages */}
                <Route path="dashboard" element={<DashboardWrapper />} />
                <Route path="builder" element={<BuilderPageWrapper />} />
                <Route path="menu-test" element={<MenuTestPage />} />
                {/* Lazy Modules */}
                <Route
                    path="crafted/pages/profile/*"
                    element={
                        <SuspensedView>
                            <ProfilePage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="crafted/pages/wizards/*"
                    element={
                        <SuspensedView>
                            <WizardsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="crafted/widgets/*"
                    element={
                        <SuspensedView>
                            <WidgetsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="crafted/account/*"
                    element={
                        <SuspensedView>
                            <AccountPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/chat/*"
                    element={
                        <SuspensedView>
                            <ChatPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/user-management/*"
                    element={
                        <SuspensedView>
                            <AdminsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/admin-management/*"
                    element={
                        <SuspensedView>
                            <AdminsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/subscription-plan-management/*"
                    element={
                        <SuspensedView>
                            <SubscriptionPlansPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/plan-management/*"
                    element={
                        <SuspensedView>
                            <PlansPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/customer-management/*"
                    element={
                        <SuspensedView>
                            <CustomersPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/gym-management/*"
                    element={
                        <SuspensedView>
                            <GymsPage />
                        </SuspensedView>
                    }
                />
                {/* <Route
                    path="apps/recepcionist-management/*"
                    element={
                        <SuspensedView>
                            <RecepcionistsPage />
                        </SuspensedView>
                    }
                /> */}
                <Route
                    path="apps/enrollment-management/*"
                    element={
                        <SuspensedView>
                            <EnrollmentsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/instructor-management/*"
                    element={
                        <SuspensedView>
                            <InstructorsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/workout-management/*"
                    element={
                        <SuspensedView>
                            <WorkoutsPage />
                        </SuspensedView>
                    }
                />

                {/* Page Not Found */}
                <Route path="*" element={<Navigate to="/error/404" />} />
            </Route>
        </Routes>
    );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue("--bs-primary");
    TopBarProgress.config({
        barColors: {
            "0": baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    });
    return (
        <Suspense fallback={<TopBarProgress />}>
            <DisableSidebar>{children}</DisableSidebar>
        </Suspense>
    );
};

export { PrivateRoutes };
