import { useListView } from "../../core/ListViewProvider";
import { WorkoutsListToolbar } from "./WorkoutsListToolbar";
import { WorkoutsListGrouping } from "./WorkoutsListGrouping";
import { WorkoutsListSearchComponent } from "./WorkoutsListSearchComponent";

const WorkoutsListHeader = () => {
    const { selected } = useListView();
    return (
        <div className="card-header border-0 pt-6">
            <WorkoutsListSearchComponent />
            {/* begin::Card toolbar */}
            <div className="card-toolbar">
                {/* begin::Group actions */}
                {selected.length > 0 ? (
                    <WorkoutsListGrouping />
                ) : (
                    <WorkoutsListToolbar />
                )}
                {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
        </div>
    );
};

export { WorkoutsListHeader };
