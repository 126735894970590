import { useQuery } from "react-query";
import { WorkoutEditModalForm } from "./WorkoutsEditModalForm";
import { isNotEmpty, QUERIES } from "../../../../../../_metronic/helpers";
import { useListView } from "../core/ListViewProvider";
import { getWorkoutById } from "../core/_requests";
import { useParams } from "react-router-dom";
import { initialWorkout } from "../core/_models";

const WorkoutEditModalFormWrapper = () => {
    const { itemIdForUpdate, setItemIdForUpdate } = useListView();
    const { id: gymSlug } = useParams();

    const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
    const {
        isLoading,
        data: workout,
        error,
    } = useQuery(
        `${QUERIES.WORKOUTS_LIST}-workout-${itemIdForUpdate}`,
        () => {
            return getWorkoutById(gymSlug, itemIdForUpdate);
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                setItemIdForUpdate(undefined);
                console.error(err);
            },
        }
    );

    console.log(workout);

    if (!itemIdForUpdate) {
        return (
            <WorkoutEditModalForm
                isWorkoutLoading={isLoading}
                workout={{ ...initialWorkout, id: undefined }}
            />
        );
    }

    if (!isLoading && !error && workout) {
        return (
            <WorkoutEditModalForm
                isWorkoutLoading={isLoading}
                workout={workout}
            />
        );
    }

    return null;
};

export { WorkoutEditModalFormWrapper };
